<template>
  <base-section id="theme-features">
    <base-section-heading title="APPS">
      <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga. -->
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5" v-text="feature.title" />

                <v-card-subtitle v-text="feature.text">
                  images
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-3"
                    height="40px"
                    :href="feature.route"
                    target="_blank"
                  >
                    App Store
                  </v-btn>
                  <v-btn
                    class="ml-2 mt-3"
                    height="40px"
                    :href="feature.playroute"
                    target="_blank"
                  >
                    Play Store
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="feature.src" />
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      // {
      //   title: 'Pixel Perfect Design',
      //   icon: 'mdi-fountain-pen-tip',
      // },
      {
        color: "primary",
        dark: true,
        title: "PENTACAM",
        icon: "mdi-cellphone",
        src: require("@/assets/fullicon512.png"),
        route: "https://apps.apple.com/nz/app/pentacam/id1561911504",
        playroute:
          "https://play.google.com/store/apps/details?id=com.jackslattery.phottto",
        text:
          "A social media app that allows you to upload fun moving images and share them with friends."
      },
      {
        color: "primary",
        dark: true,
        title: "SPOTIFRIENDS",
        icon: "mdi-cellphone",
        src: require("@/assets/fullicon copy.png"),
        route: "https://apps.apple.com/nz/app/pentacam/id1561911504",
        playroute:
          "https://play.google.com/store/apps/details?id=com.jackslattery.spotifyfriends",
        text:
          "An app that connects to your Spotify account and allows you to view what your friends are listening to on a mobile device."
      }
      // {
      //   color: 'primary',
      //   dark: true,
      //   title: 'Easily Customizable',
      //   icon: 'mdi-pencil-box-outline',
      // },
      // {
      //   title: 'Image Parallax',
      //   icon: 'mdi-image-size-select-actual',
      // },
      // {
      //   title: 'Seo Optimized',
      //   icon: 'mdi-ice-pop',
      // },
      // {
      //   title: '24/7 Support',
      //   icon: 'mdi-help-circle-outline',
      // },
    ]
  })
};
</script>
